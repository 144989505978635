import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../../../Constant/slugs';

export default function Dashboard() {
  const data = [
    {
      slug: slugs.exam,
      image: "https://api.kmsinstitutenepal.com/wp-content/uploads/2023/08/All-Exam.jpeg"
    },
    {
      slug: slugs.demo_exam,
      image: "https://api.kmsinstitutenepal.com/wp-content/uploads/2023/08/Demo-Exam.jpeg"
    },
    {
      slug: slugs.chapter_wise_exam,
      image: "https://api.kmsinstitutenepal.com/wp-content/uploads/2023/08/Chapter-Exam.jpeg"
    },
    {
      slug: slugs.exam_result_list,
      image: "https://api.kmsinstitutenepal.com/wp-content/uploads/2023/08/Exam-Result.jpeg"
    },
    {
      slug: slugs.chapter_wise_exam_result_list,
      image: "https://api.kmsinstitutenepal.com/wp-content/uploads/2023/08/Chapter-Result.jpeg"
    },
    {
      slug: slugs.books,
      image: "https://api.kmsinstitutenepal.com/wp-content/uploads/2023/08/Books.jpeg"
    },
  ]
  const Block = ({ slug, image }) => {
    return (
      <div className="col-lg-4 col-6" >
        <Link style={{
          textDecorationLine: 'none',
          display: 'flex',
          justifyContent: 'center',
          // color: "#000",
        }}
          to={`${slug}`}
        >
        
            <img
            className="home-box-2"
            // style={{
            //     backgroundColor: "#fff", border: "1px solid black",
            //     width: "220px", height: "220px",
            //     borderRadius: "5px"
            //   }}
              src={image}
            />
        </Link>

      </div>
    )
  }
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 form-view mt-3">
            <div className="row" style={{ margin: "10px" }}>
              {data.map((item, index) => (
                <Block slug={item.slug} image={item.image} />
              ))}
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}